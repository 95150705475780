import * as React from 'react'
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';
//import { useLocation } from 'react-router-dom';
import { forwardRef, useEffect, useCallback } from 'react';
// material
import { Box } from '@mui/material';
// utils
import track from '../../utils/analytics';
import MainLayout from '../../layouts/main';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const RootStyle = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(11)
    }
  }));

const HomePage = forwardRef(({ children, title = '', layout='main',...other }, ref) => {
  const { pathname } = "";//useLocation();

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: pathname
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <RootStyle>
        <Box ref={ref} {...other}>
        <Helmet>
            <title>{title}</title>
        </Helmet>
        {layout === 'main' ?
            (<MainLayout>
            {children}
            </MainLayout>)
            :
            children
        }
        </Box>
    </RootStyle>
  );
});

HomePage.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default HomePage;
