import * as React from 'react'
import { motion } from 'framer-motion';
//import { Link as RouterLink } from 'react-router-dom';
import { GatsbyLink as RouterLink } from "gatsby-theme-material-ui";
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import HomePage from '../components/heroes/HomePage';
import { PageNotFoundIllustration } from '../assets';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <HomePage title="404 Page Not Found">
      <RootStyle>
        <Container>
          <MotionContainer initial="initial" open>
            <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
              <motion.div variants={varBounceIn}>
                <Typography variant="h3" paragraph>
                  Sorry, page not found!
                </Typography>
              </motion.div>
              <Typography sx={{ color: 'text.secondary' }}>
                Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be sure to check
                your spelling.
              </Typography>

              <motion.div variants={varBounceIn}>
                <PageNotFoundIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
              </motion.div>

              <Button to="/" size="large" variant="contained" component={RouterLink}>
                Go to Home
              </Button>
            </Box>
          </MotionContainer>
        </Container>
      </RootStyle>
    </HomePage>
  );
}
